import React from 'react';
import Labyrinth from '../utils/Labyritinth'
import CountdownTimer from './CountdownTimer';

class GameBoard extends React.Component {
  // Your component logic goes here
  state = {
    size:7,
    exits:3,
    solution:false,
    cursor:null,
    answerLength:0,
    showTimer:false,
    score:0,
    showFinalScore:false
  }

  constructor(props) {
    super(props)
    this.labyrinth=null
    this.answer=Array()
    this.answerLength=0
    this.allowMovement=false
    this.countdown=React.createRef()
  }

  
  startGame(){
    this.allowMovement=true
    this.setState({showFinalScore:false})
    this.setState({score:0})
    this.setState({answerLength:0})
    this.setState({showTimer:true})
    this.answer=Array()
    this.buildLabyrinth()
    
    if(this.countdown.current!=null){
      this.countdown.current.resetTimer()
    }
    
  }
  resetAnswer(){
    const center=this.labyrinth.getCenterHash()
    this.currentPosition=center
    this.answer=Array()
    this.answer.push(center)
    this.setState({answerLength:0})
    this.setState({cursor: center})
    document.getElementById(center).classList.add('cursor')
  }
  buildLabyrinth(){
    this.labyrinth=new Labyrinth(this.state.size, this.state.exits)
    
    this.setState({showTimer:true})
    this.resetAnswer()
    
  }
  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyDown);

  }
  componentDidUpdate(){

  }

  showSolution(){
    this.setState({solution:true})
    const allWithClass = Array.from(
    document.getElementsByClassName('solution')
    );

    allWithClass.forEach(element => {
        element.classList.add('show-solution')
    });
  }

  updateAnswerLength(){
    var answerLength=0
    for(var n in this.answer){
        if(isNaN(this.labyrinth.graph[this.answer[n]].element.value))
            continue

        answerLength+=Number(this.labyrinth.graph[this.answer[n]].element.value)
    }
    this.answerLength=answerLength
    this.setState({answerLength:answerLength})
  }
  endGame(){
    //this.showSolution()
    this.allowMovement=false
    this.setState({showFinalScore: true})
    this.setState({showTimer: false})
  }
  handleTimerEnd = () => {
    this.endGame()
  };

  endRound(){
    var elements=Array.from(document.getElementsByClassName('cursor'))
    if(this.answerLength>=this.labyrinth.maximum){
        elements.forEach((x)=>{
            x.classList.add('bad-answer')
            x.classList.remove('cursor')
        })

    }
    if(this.answerLength<this.labyrinth.maximum && this.answerLength>this.labyrinth.minimum){
        elements.forEach((x)=>{
            x.classList.add('ok-answer')
            x.classList.remove('cursor')
        })
        this.setState({score: Math.round(this.state.score+5*this.labyrinth.minimum/this.answerLength)})
    }
    if(this.answerLength<=this.labyrinth.minimum){
        elements.forEach((x)=>{
            x.classList.add('good-answer')
            x.classList.remove('cursor')
        })
        this.setState({score: this.state.score+10})
    }
    this.showSolution()
    var interval=setInterval(() => {this.buildLabyrinth(); clearInterval(interval)},2000)
  }

  addElementToAnswer(nextPosition){
    this.answer.push(nextPosition)
    this.updateAnswerLength()

    if(this.labyrinth.graph[nextPosition].element.exit){
        this.endRound()
    }
  }
  undoPath(nextElement){
    var auxElemHash=null
    do{
        auxElemHash=this.answer.pop()
        if(auxElemHash==null){
          return
        }
        var auxElem=document.getElementById(auxElemHash)
        auxElem.classList.remove('cursor')
    }while(auxElemHash!=nextElement && auxElemHash!=this.labyrinth.getCenterHash() && this.answer.length>0)
    this.updateAnswerLength()
  }

  moveCursor(positionDelta, border){
    if(!this.allowMovement){
      return
    }

    const currentElement=document.getElementById(this.currentPosition)
    if(this.labyrinth.graph[this.currentPosition].element.border.includes(border)){
        var nextPosition=Number(this.currentPosition)+positionDelta

        if(nextPosition>=10){
            nextPosition=String(nextPosition)
        }else{
            nextPosition='0'+String(nextPosition)
        }
        const nextElement=document.getElementById(nextPosition)
        this.currentPosition=nextPosition
        if(nextElement.classList.contains('cursor')){
            this.undoPath(nextPosition)
        }
        
        nextElement.classList.add('cursor')
        this.addElementToAnswer(nextPosition)      
        
    }
  }

  handleTouchStart = (event) => {
    console.log('Touch started');
    // Your logic for touch start event
  }

  handleTouchMove = (event) => {
    if(!this.allowMovement){
      return
    }

    if(event.changedTouches[0].target.id==this.labyrinth.getCenterHash()){
      
      var target = document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
      if(target){
        if(typeof target.data !="undefined"){
          if(target.data=='labyrinth'){
            const nextElement=target.id
    
            switch(Number(target.id)-Number(this.currentPosition)){
              case -1:
                this.moveCursor(-1, 'left')
                break
              case 1:
                this.moveCursor(1, 'right')
                break
              case 10:
                this.moveCursor(10, 'bottom')
                break
              case -10:
                this.moveCursor(-10, 'top')
                break
            }
            
          }
        }
      }
      
      
    }
    // Your logic for touch move event
  }

  handleTouchEnd = (event) => {
    console.log('Touch ended');
    this.undoPath(this.labyrinth.getCenterHash())
    this.resetAnswer()
  }

  handleSizeChange(event) {
    this.setState({ size: event.target.value })
  }
  handleExitsChange(event) {
    this.setState({ exits: event.target.value })
  }
  handleKeyDown = (e) => {
    if (e.key === "ArrowRight") {
        this.moveCursor(1, 'right')
    }
    if (e.key === "ArrowLeft") {
        this.moveCursor(-1, 'left')
    }
    if (e.key === "ArrowUp") {
        this.moveCursor(-10, 'top')
    }
    if (e.key === "ArrowDown") {
        this.moveCursor(10, 'bottom')
    }
  }
  showFinalScore(){
    if(this.state.showFinalScore){
      return(
        <div id="final-score" className='exact-center'>
          <label htmlFor="answerLength">Final Score</label>
          <br></br>
          <span id="answerLength">{this.state.score}</span>
        </div>
      )
    }
  }
  render(){
    return (
        <div>
            <label htmlFor="size">Size:</label>
            <input type="text" id="size" value={this.state.size} onChange={this.handleSizeChange.bind(this)}/>
            <label htmlFor="size">Exits:</label>
            <input type="text" id="exits" value={this.state.exits} onChange={this.handleExitsChange.bind(this)}/>
            
            <button onClick={this.startGame.bind(this)}>Play!</button>
            {/* <label htmlFor="answerLength">Answer Length: </label>
            <span id="answerLength">{this.state.answerLength}</span> */}
            {this.state.showTimer?<CountdownTimer ref={this.countdown} initialTime={60} onTimerEnd={this.handleTimerEnd}/>:''}
            <div>
              <label htmlFor="answerLength">Score: </label>
              <span id="answerLength">{this.state.score}</span>
            </div>
            <div id="labyrinth" onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove} onTouchEnd={this.handleTouchEnd}>
            </div>
            {
              this.showFinalScore()
            }
        </div>
    )
  }
}

export default GameBoard;