class Labyrinth{
    constructor(size,exits){
        this.graph= Array()
        this.size=Number(size)
        this.exits=Number(exits)
        this.minimum=0
        this.maximum=0
        
        this.createLabyrinth(this.size,this.exits)
        this.renderLabyrinth()
        
    }


generateElement(max=10, exit=false, border=Array()){
    return {value:Math.floor(Math.random() * max), outs:1+Math.floor(Math.random() * max), exit: exit, border: border, solution:false}
}

initLabyrinthExits(matrix,size,outs,max){
    var exits=Array()
    for(var i=0; i<outs; i++){
        var outBoundary=Math.floor(Math.random() * 4)
        var x,y
        switch(outBoundary){
            //top
            case 0:
                do{
                    x=outBoundary=Math.floor(Math.random() * size)
                    y=0;
                }while(matrix[x][y]>0)
                break
            //bottom
            case 1:
                do{
                    x=outBoundary=Math.floor(Math.random() * size)
                    y=size-1
                }while(matrix[x][y]>0)  
                break              
            //left
            case 2:
                do{
                    x=0
                    y=outBoundary=Math.floor(Math.random() * size)
                }while(matrix[x][y]>0)   
                break
            //right
            case 3:
                do{
                    x=size-1
                    y=outBoundary=Math.floor(Math.random() * size)
                }while(matrix[x][y]>0)     
                break   
        }
        exits[i]=[x, y]
        matrix[x][y]=this.generateElement(max, true)
        this.graph[String(x)+String(y)]={element: matrix[x][y], neighbours:this.findNeighbours(x,y,size), position:[x,y]}
    }
    return exits
}
calculateInverseBorder(borderPosition){
    switch (borderPosition){
        case 'top':
            return ['bottom']
        case 'bottom':
            return ['top']
        case 'right':
            return ['left']
        case 'left':
            return ['right']
    }
    return 0
}
openBorders(sourceElement, edge){
    var source=edge[0], destination=edge[1]
    if(source[0]>destination[0]){
        sourceElement.border.push('top')
    }
    if(source[0]<destination[0]){
        sourceElement.border.push('bottom')
    }
    if(source[1]>destination[1]){
        sourceElement.border.push('left')
    }
    if(source[1]<destination[1]){
        sourceElement.border.push('right')
    }
}
findNeighbours(i,j,size){
    var neighbours={}
    var neighbourX, neighbourY
    var instructionIndex=[0,1,2,3]
    instructionIndex.sort(() => Math.random() - 0.5)
    for(var n in instructionIndex){
        switch(instructionIndex[n]){
            case 0:
                neighbourX=Math.min(i+1,size-1)
                neighbourY=j
                if(neighbourX!=i || neighbourY!=j){
                    neighbours[String(neighbourX)+String(neighbourY)]=[neighbourX, neighbourY]
                }
                break

            case 1:
                neighbourX=i
                neighbourY=Math.min(j+1,size-1)
                if(neighbourX!=i || neighbourY!=j){
                    neighbours[String(neighbourX)+String(neighbourY)]=[neighbourX, neighbourY]
                }
                break
            case 2:
                neighbourX=Math.max(i-1,0)
                neighbourY=j
                if(neighbourX!=i || neighbourY!=j){
                    neighbours[String(neighbourX)+String(neighbourY)]=[neighbourX, neighbourY]
                }
                break;
            case 3:
                neighbourX=i
                neighbourY=Math.max(j-1,0)
                if(neighbourX!=i || neighbourY!=j){
                    neighbours[String(neighbourX)+String(neighbourY)]=[neighbourX, neighbourY]
                }
                break
        }
    }
    

    return neighbours

}
createLabyrinthElements(matrix, size,out=4,max=10){
    for(var i=0; i<size; i++){
        for(var j=0; j<size; j++){
            if(matrix[i][j]===0){
                matrix[i][j]=this.generateElement(max,false)
                this.graph[String(i)+String(j)]={element: matrix[i][j], neighbours:this.findNeighbours(i,j,size), position:[i,j]}
            }
        }
    }
    return matrix
}
findPath(source,destination, current, numberPaths=2, paths=Array(), visitedNodes={}, path=Array()){
    var currentElementHash=String(current[0])+String(current[1])
    var destinationElementHash=String(destination[0])+String(destination[1])
    var keys=Array.from(Object.keys(this.graph[currentElementHash].neighbours)).sort(() => Math.random() - 0.5)
    for(var n in keys){
        if(paths.length>=numberPaths){
            return 
        }
        visitedNodes[currentElementHash]=currentElementHash
            // console.log('current '+String(current)+' n '+n)
            // console.log(visitedNodes)
            if(typeof visitedNodes[keys[n]]=='undefined'){
                //avoid other exits besides the destination               
                if(this.graph[keys[n]].element.exit===false || keys[n]==destinationElementHash){
                    path.push([current, this.graph[keys[n]].position])
                    if(keys[n]==destinationElementHash){
                        paths.push(path)
                    }else{
                        this.findPath(source, destination, this.graph[keys[n]].position, numberPaths,paths,visitedNodes, path)
                    }
                    
                }   
            }
            
    }
}
findPaths(source,destination, numberPaths){
    var paths=Array(), returnPaths=Array(), path=Array()
    this.findPath(source,destination,source,numberPaths, paths)
    for(var i=0; i<numberPaths; i++){
        path=paths[i]
        returnPaths.push(path)
        //delete paths from graph
        for(var edge in path){
            var sourceElementHash=String(path[edge][0][0])+String(path[edge][0][1])
            var destinationElementHash=String(path[edge][1][0])+String(path[edge][1][1])
            //delete this.graph[sourceElementHash].neighbours[destinationElementHash]
            this.openBorders(this.graph[sourceElementHash].element,path[edge])
            //delete this.graph[destinationElementHash].neighbours[sourceElementHash]
            this.openBorders(this.graph[destinationElementHash].element,[path[edge][1], path[edge][0]])
        }
    }
    return returnPaths
}
minDistance(distances, visitedNodes, shortest=true){
    let min = Number.MAX_VALUE;
    let minIndex = -1;

    for (var v in distances) {
        if(shortest){
            if (!visitedNodes[v] && distances[v] <= min) {
                min = distances[v];
                minIndex = v;
            }
        }else{
            if (!visitedNodes[v] && distances[v] >= min) {
                min = distances[v];
                minIndex = v;
            }
        }
    }

    return minIndex;
    
}
findShortestPaths(graph, source, exits){

    let distances = Object.keys(graph).reduce((result, x)=>{result[x]=Number.MAX_VALUE; return result},{})
    let visitedNodes = Object.keys(graph).reduce((result, x)=>{result[x]=false; return result},{})
    let path = Object.keys(graph).reduce((result, x)=>{result[x]= Array(); return result},{})
    distances[source]=0

    for(var i=0; i<Object.keys(graph).length-1; i++){
        let u = this.   minDistance(distances, visitedNodes);
        visitedNodes[u] = true;
        for (var n in graph[u].neighbours) {
            //has node been visited
            if (!visitedNodes[n]){
                //is node reachable???
                if (distances[u]!=Number.MAX_VALUE){
                    if((distances[u] + this.graph[n].element.value) < distances[n]){
                        distances[n]=distances[u] + this.graph[n].element.value
                        path[n]=Array.from(path[u])
                        path[n].push([this.graph[u].position, this.graph[n].position])
                    }
                }

            }
            
        }
    }
    var auxMinimum=Number.MAX_VALUE
    var auxMaximum=0
    var returnPath=Array()
    for (n in exits){
        if(distances[String(exits[n][0])+String(exits[n][1])]<auxMinimum){
            auxMinimum=distances[String(exits[n][0])+String(exits[n][1])]
            returnPath=path[String(exits[n][0])+String(exits[n][1])]
        }
        if(distances[String(exits[n][0])+String(exits[n][1])]>auxMaximum){
            auxMaximum=distances[String(exits[n][0])+String(exits[n][1])]
            //returnPath=path[String(exits[n][0])+String(exits[n][1])]
        }
    }
    this.minimum=auxMinimum
    this.maximum=auxMaximum
    return {path:returnPath, minimum:auxMinimum}
}

buildGraphFromPath(path, graph=Array()){
    for(var edge in path){
        var neighbours=Array(), reverseNeighbours=Array()
        var sourceX=path[edge][0][0]
        var sourceY=path[edge][0][1]
        var destinationX=path[edge][1][0]
        var destinationY=path[edge][1][1]
        var graphHash=String(sourceX)+String(sourceY)
        var neighbourHash=String(destinationX)+String(destinationY)
        neighbours[neighbourHash]=path[edge][1]
        reverseNeighbours[graphHash]=path[edge][0]

        if(typeof graph[graphHash]=='undefined'){
            graph[graphHash]={element: this.matrix[sourceX][sourceY], neighbours:neighbours, position:path[edge][0]}
        }else{
            graph[graphHash].neighbours[neighbourHash]=path[edge][1]
        }
        if(typeof graph[neighbourHash]=='undefined'){
            graph[neighbourHash]={element: this.matrix[destinationX][destinationY], neighbours:reverseNeighbours, position:path[edge][1]}
        }else{
            graph[neighbourHash].neighbours[graphHash]=path[edge][0]
        }
    }
    return graph
}
getCenterHash(){
    return String(Math.floor(this.size/2))+String(Math.floor(this.size/2))
}

createLabyrinth(size, exits=4, max=10, pathNumber=1){
    this.matrix = Array(size).fill().map(() => Array(size).fill(0))
    //Find Center
    var center= Math.floor(size/2)
    this.matrix[center][center]={value:'X', outs:4, border:Array()}
    this.graph[String(center)+String(center)]={element: this.matrix[center][center], neighbours:this.findNeighbours(center,center,size), position:[center,center]}
    //init Exits
    var exitElements=this.initLabyrinthExits(this.matrix,size,exits)
    //init elements
    this.matrix=this.createLabyrinthElements(this.matrix,size,exits)


    var paths=Array()
    var finalGraph=Array()
    for(var i=0; i<exitElements.length; i++){  
        paths[i]=this.findPaths([center,center],exitElements[i],pathNumber)
        for(var n in paths[i]){
            this.buildGraphFromPath(paths[i][n],finalGraph)
        }     
    }

    var result=this.findShortestPaths(finalGraph, String(center)+String(center), exitElements)
    for(n in result.path){
        this.matrix[result.path[n][0][0]][result.path[n][0][1]].solution=true
        this.matrix[result.path[n][1][0]][result.path[n][1][1]].solution=true
    }
    return paths

}

renderLabyrinth(solution=false){
    
    var size=this.matrix[0].length
    var table=document.createElement("table");
    var board
    if(document.getElementById('labyrinth')!=null){
        board=document.getElementById('labyrinth')
        if(board.firstChild!=null){
            board.firstChild.remove()
        }
        
    }else{
        var board= document.createElement('div')
        board.id='labyrinth'
        document.body.appendChild(board)
    }

    for (var i = 0; i < size; i++) {
        var row = table.insertRow(i);
        for(var j = 0; j < size; j++) {
            var cell=row.insertCell(j)
            cell.id=String(i)+String(j)
            var cellBackground='transparent'
            if(this.matrix[i][j]===0){
                var cellValue=0
            }else{
                var cellValue=this.matrix[i][j].value
                if(this.matrix[i][j].exit){
                    cellBackground='lightgreen'
                }if(this.matrix[i][j].solution ){
                    if(solution){
                        cellBackground='#b5e1eb'
                    }
                    
                    cell.className='solution'
                }
                
            }
            cell.data='labyrinth'
            cell.style.backgroundColor=cellBackground
            
            for(var z in this.matrix[i][j].border){
                cell.style['border-'+this.matrix[i][j].border[z]+'-color']='transparent'
            }
            cell.appendChild(document.createTextNode(cellValue))
        }
    }

    board.appendChild(table);
}


}
export default Labyrinth;

