import React, { Component } from 'react';

class CountdownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.props.initialTime,
    };
  }
  startTimer = () => {
    this.timer = setInterval(() => {
      if (this.state.time > 0) {
        this.setState((prevState) => ({ time: prevState.time - 1 }));
      } else {
        this.props.onTimerEnd();
        clearInterval(this.timer);
      }
    }, 1000);
  };
  componentDidMount() {
    this.startTimer()
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  resetTimer = () => {
    clearInterval(this.timer);
    this.setState({ time: this.props.initialTime }, () => {
      this.startTimer();
    });
  };
  render() {
    return (
      <div>
        <p>Time remaining: {this.formatTime(this.state.time)}</p>
      </div>
    );
  }
}

export default CountdownTimer;
